import React from "react";
import image from "../../../assets/lifestyle/artconsultancy.png";


const ArtConsultancy = () => (
  <div className="d-flex container justify-content-center flex-column" style={{}}>
    <img src={image} alt="first collection" height={window.innerHeight}/>
  </div>
);

export { ArtConsultancy };
